export let createQualificationSchema = {
  WorkIdentifiedObject: {
    IdentifiedObject: {
      name: "",
      description: "",
      enproCode: "",
      names: [],
    },
  },
  qualificationID: "",
};
