<template>
  <div class="input-group p-0">
    <template v-if="type === 'textarea'">
      <textarea
        type="text"
        ref="inputField"
        :id="id"
        v-model="lValue"
        :readonly="!editable"
        :required="required"
        :name="name"
        :placeholder="placeholder"
        :class="['form-control', { float: float }]"
      ></textarea>
    </template>
    <template v-else>
      <input
        ref="inputField"
        :id="id"
        :type="type"
        :step="step"
        v-model="lValue"
        :required="required"
        :readonly="!editable"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :class="['form-control', { float: float }]"
      />
    </template>
  </div>
</template>

<script>
import { generateUUID } from "@/plugins/uuidGenerator";

export default {
  props: {
    value: [String, Number],
    editable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default() {
        return false;
      },
    },
    name: String,
    autocomplete: String,
    step: {
      type: String,
      default: "any",
    },
    placeholder: String,
    float: Boolean,
  },
  data() {
    return {
      id: generateUUID(),
    };
  },
  computed: {
    lValue: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.form-control {
  &.float {
    background-color: $main-2;
    &:read-only {
      background-color: $main-1;
      border: 2px solid $main-2;
      border-radius: 6px;
    }
  }
  &:not(.float) {
    background-color: $main-1;
    &:read-only {
      background-color: $main-2;
      border: 2px solid $main-1;
      border-radius: 6px;
    }
  }
}
</style>
