import axios from "@/plugins/axios";
import { parse_qualification_to_create } from "@/services/references/qualification/parsers";

/**
 * Получение списка QualificationRequirement
 * @return {Promise<QualificationObject[]>}
 */
export const API_qualification_list = () => {
  return axios("/qualificationRequirement?perPage=5000", {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Получение QualificationRequirement
 * @param {number} qualificationId
 * @return {Promise<QualificationObject>}
 */
export const API_qualification_view = (qualificationId) => {
  return axios(`/qualificationRequirement/${qualificationId}/`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Обновление QualificationRequirement
 * @param {number} qualificationId
 * @param {Object} value
 * @return {Promise<QualificationObject>}
 */
export const API_qualification_update = (qualificationId, value) => {
  return axios(`/qualificationRequirement/${qualificationId}/`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: value,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание QualificationRequirement
 * @param {Object} value
 * @return {Promise<QualificationObject>}
 */
export const API_qualification_create = (value) => {
  return axios(`/qualificationRequirement/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_qualification_to_create(value),
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Удаление QualificationRequirement
 * @param {number} qualificationId
 * @return {Promise<Boolean>}
 */
export const API_qualification_delete = (qualificationId) => {
  return axios(`/qualificationRequirement/${qualificationId}/`, {
    method: "DELETE",
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};
