<template>
  <button
    :class="['ui-btn', { float }]"
    :title="text"
    @click="$emit('click')"
    :disabled="!!disabled"
    :type="type"
  >
    <i v-if="leftIcon" :class="leftIcon"></i>
    <span v-if="text">{{ text }}</span>
    <i v-if="rightIcon" :class="rightIcon"></i>
    <i
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    ></i>
  </button>
</template>

<script>
export default {
  props: {
    leftIcon: String,
    rightIcon: String,
    type: {
      type: String,
      default: "button",
    },
    text: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    float: Boolean,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    setStatus(s) {
      this.isLoading = s;
    },
  },
};
</script>

<style lang="scss">
.ui-btn {
  padding: 4px 8px;
  &.float {
    background-color: transparent;
    border: 1px solid $highlight;
    color: $highlight;

    &:hover {
      border-color: $highlight-hover;
      color: $highlight-hover;
    }

    &:disabled {
      pointer-events: none;
      border-color: $highlight-disabled;
      color: $highlight-disabled-text;
    }
  }
  &.remove {
    background-color: darkred;
    color: $highlight-hover;

    &:hover {
      background-color: red;
    }

    &:disabled {
      pointer-events: none;
      border-color: $highlight-disabled;
      color: $highlight-disabled-text;
    }
  }
  &:not(.float, .remove) {
    background-color: $highlight;
    &:hover {
      background-color: $highlight-hover;
    }
    &:disabled {
      pointer-events: none;
      background-color: $highlight-disabled;
      color: $highlight-disabled-text;
    }
  }

  border-radius: $border-radius;

  i {
    vertical-align: middle;
    &:not(:only-child):first-child {
      margin-right: 8px;
    }
    &:not(:only-child):last-child {
      margin-left: 8px;
    }
  }
}
</style>
