<template>
  <form @submit.prevent="saveQualification">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="headerData.title"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
        />
        <UIButton
          type="button"
          v-show="editable"
          text="Удалить"
          leftIcon="bi-trash"
          @click="deleteQualification"
          float
        />
      </div>
    </div>

    <div class="row gap-3">
      <dl class="col">
        <div class="row gap-2">
          <div class="row">
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkIdentifiedObject.IdentifiedObject.name"
                editable
                required
              />
            </dl>
          </div>

          <div class="row">
            <dt class="col">Описание:</dt>
            <dl class="col">
              <EditableInput
                v-model="data.WorkIdentifiedObject.IdentifiedObject.description"
                editable
                required
              />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Код:</dt>
            <dl class="col">
              <EditableInput v-model="data.qualificationID" editable required />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import {
  API_qualification_view,
  API_qualification_update,
  API_qualification_delete,
} from "@/services/references/qualification";
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";

export default {
  name: "view",
  components: { UIButton, EditableInput },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return true;
        default:
          return false;
      }
    },
    headerData: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return {
            title: "Редактирование норматива",
          };
        default:
          return {
            title: "Просмотр норматива",
          };
      }
    },
  },
  data() {
    return {
      /**
       * @type QualificationObject
       */
      data: null,
    };
  },
  mounted() {
    API_qualification_view(parseInt(this.$route.params.qualityId)).then(
      (res) => {
        this.data = res;
      }
    );
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/references/qualification-requirement/${this.$route.params.qualityId}/edit`,
      });
    },
    deleteQualification() {
      API_qualification_delete(parseInt(this.$route.params.qualityId)).finally(
        () => {
          this.$router.push({
            path: "/references/qualification-requirement/",
          });
        }
      );
    },
    saveQualification() {
      API_qualification_update(
        parseInt(this.$route.params.qualityId),
        this.data
      ).then(() => {
        this.$router.push({
          path: `/references/qualification-requirement/${this.$route.params.qualityId}`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
